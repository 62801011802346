import { FormEvent, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import {
  CaretDownIcon,
  CaretUpIcon,
  Checkbox,
  Modal,
  ModalPrimaryActionButton,
  ModalTertiaryActionButton,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Typography,
  formatAsCurrency,
} from '@la/ds-ui-components';
import { Button } from '@la/ds-ui-components';
import { RadioButton } from 'components/RadioButton/RadioButton';
import {
  PaymentPlanSummary,
  PaymentPlanSummaryInstallment,
  PaymentTermsData,
  PaymentTermsItem,
} from '../Checkout.types';
import { DiscountBadge } from '../DiscountBadge/DiscountBadge';
import { getPaymentPlanSubTotal } from '../utils/paymentPlan';
import * as S from './PaymentTermsModal.styles';

export const PAY_IN_FULL_DETAIL = 'Pay in full';
export const PAY_IN_FULL_VALUE = 'FULL';

const PAYMENT_TERMS_FORM_ID = 'paymentTerms';

export type PaymentTermsFormFields = {
  [key: string]: {
    paymentTerm: string;
    payLater?: boolean;
  };
};

export type PaymentTermsModalProps = {
  closeModal: () => void;
  onSubmit: (selections: PaymentTermsFormFields) => void;
  open: boolean;
  paymentTermsData: PaymentTermsData;
};

/* PaymentTermsModal */
export default function PaymentTermsModal({
  closeModal,
  onSubmit,
  open,
  paymentTermsData,
}: PaymentTermsModalProps) {
  const transformPaymentTermSelections = (
    formData: FormData
  ): PaymentTermsFormFields => {
    const fields: PaymentTermsFormFields = {};

    paymentTermsData.forEach(({ itemId }) => {
      const paymentTerm = formData.get(itemId);

      if (paymentTerm) {
        if (paymentTerm === 'FULL') {
          fields[itemId] = { paymentTerm };
        } else {
          const payLater = formData.get(getItemPayLaterId(itemId));
          fields[itemId] = {
            paymentTerm: paymentTerm.toString(),
            payLater: payLater?.toString() === 'true' ? true : false,
          };
        }
      }
    });

    return fields;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    onSubmit(transformPaymentTermSelections(formData));
  };

  return (
    <Modal
      onOpenChange={closeModal}
      open={open}
      primaryAction={
        <ModalPrimaryActionButton form={PAYMENT_TERMS_FORM_ID} type="submit">
          Save
        </ModalPrimaryActionButton>
      }
      tertiaryAction={
        <ModalTertiaryActionButton onClick={closeModal}>
          Cancel
        </ModalTertiaryActionButton>
      }
      size="medium"
      title="Payment terms"
    >
      <S.PaymentTermsModalItems
        id={PAYMENT_TERMS_FORM_ID}
        onSubmit={handleSubmit}
      >
        {paymentTermsData.map((item, index) => (
          <PaymentTermModalItem key={index} {...item} />
        ))}
      </S.PaymentTermsModalItems>
    </Modal>
  );
}
/* */

function PaymentTermModalItem({
  itemId,
  payInFull,
  payLater,
  paymentTerm,
  paymentPlans,
  subTotal,
  tournamentName,
}: PaymentTermsItem) {
  const [selectedOption, setSelectedOption] = useState<string>(paymentTerm);

  const fullAmount = Number(subTotal);
  const discountAmount = useMemo(() => {
    const selectedPaymentPlan =
      paymentTerm !== PAY_IN_FULL_VALUE
        ? paymentPlans.find(
            (paymentPlan) => paymentPlan.ngPaymentPlanId === selectedOption
          )
        : null;

    if (selectedPaymentPlan) {
      return getPaymentPlanSubTotal(selectedPaymentPlan);
    }

    return fullAmount;
  }, [fullAmount, paymentPlans, paymentTerm, selectedOption]);

  return (
    <S.PaymentTermsModalItem>
      <S.PaymentTermsModalItemHeader>
        <Typography variant="headline" size="xs">
          {tournamentName}
        </Typography>
        <Typography variant="headline" size="xs">
          {discountAmount < fullAmount ? (
            <S.DiscountAmount>
              {formatAsCurrency(discountAmount)}
            </S.DiscountAmount>
          ) : null}
          <S.FullAmount $hasDiscount={discountAmount < fullAmount}>
            {formatAsCurrency(fullAmount)}
          </S.FullAmount>
        </Typography>
      </S.PaymentTermsModalItemHeader>
      <PaymentTermModalItemPaymentPlans
        itemId={itemId}
        payInFull={payInFull}
        payLater={payLater}
        paymentPlans={paymentPlans}
        paymentTerm={selectedOption}
        onPaymentPlanSelect={setSelectedOption}
      />
    </S.PaymentTermsModalItem>
  );
}

function PaymentTermModalItemPaymentPlans({
  itemId,
  payInFull,
  payLater,
  paymentPlans,
  paymentTerm,
  onPaymentPlanSelect,
}: {
  itemId: string;
  payInFull?: boolean;
  payLater?: boolean;
  paymentPlans: PaymentPlanSummary[];
  paymentTerm: string;
  onPaymentPlanSelect: (paymentTerm: string) => void;
}) {
  const getCheckedState = (paymentPlan: PaymentPlanSummary): boolean => {
    return paymentTerm === paymentPlan.ngPaymentPlanId;
  };

  const handlePlanOptionSelect = (paymentPlan: PaymentPlanSummary): void => {
    onPaymentPlanSelect(paymentPlan.ngPaymentPlanId);
  };

  return (
    <S.PaymentTermModalItemPaymentPlans>
      {payInFull ? (
        <S.PaymentTermsModalRow $showDivider={!!paymentPlans.length}>
          <S.PaymentTermModalItemRadioButton>
            <RadioButton
              iconColor="var(--blue-grey-600)"
              checked={paymentTerm === PAY_IN_FULL_VALUE}
              handleChange={() => onPaymentPlanSelect(PAY_IN_FULL_VALUE)}
              name={itemId}
              label={PAY_IN_FULL_DETAIL}
              value={PAY_IN_FULL_VALUE}
            />
          </S.PaymentTermModalItemRadioButton>
        </S.PaymentTermsModalRow>
      ) : null}
      {paymentPlans.map((paymentPlan, index) => (
        <S.PaymentTermsModalRow
          $showDivider={index !== paymentPlans.length - 1}
          key={`${itemId}-payment-option-${index}`}
        >
          <PaymentTermModalItemRadioButton
            checked={getCheckedState(paymentPlan)}
            handleChange={() => handlePlanOptionSelect(paymentPlan)}
            itemId={itemId}
            label={paymentPlan.name}
            name={itemId}
            payLater={payLater && paymentPlan.ngPaymentPlanId === paymentTerm}
            paymentPlan={paymentPlan}
          />
        </S.PaymentTermsModalRow>
      ))}
    </S.PaymentTermModalItemPaymentPlans>
  );
}

type PaymentTermModalItemRadioButtonProps = {
  checked?: boolean;
  handleChange?: () => void;
  itemId: string;
  label: string;
  name: string;
  payLater?: boolean;
  paymentPlan: PaymentPlanSummary;
};

function PaymentTermModalItemRadioButton({
  checked,
  handleChange,
  itemId,
  label,
  name,
  payLater,
  paymentPlan,
}: PaymentTermModalItemRadioButtonProps) {
  const [isShowingDetails, setIsShowingDetails] = useState<boolean>(!!checked);

  const detailsIcon = isShowingDetails ? (
    <CaretUpIcon variant="filled" />
  ) : (
    <CaretDownIcon variant="filled" />
  );
  const detailsText = `${isShowingDetails ? 'Hide' : 'View'} details`;

  const handleSelect = (): void => {
    if (handleChange) {
      handleChange();
    }

    if (!isShowingDetails) {
      setIsShowingDetails(true);
    }
  };

  return (
    <>
      <S.PaymentTermModalItemRadioButton>
        <S.PaymentTermModalItemHeader>
          <S.PaymentTermModalItemText>
            <RadioButton
              iconColor="var(--blue-grey-600)"
              checked={checked}
              handleChange={handleSelect}
              name={name}
              label={label}
              value={paymentPlan.ngPaymentPlanId}
            />
            {paymentPlan.priceAdjustment ? (
              <DiscountBadge priceAdjustment={paymentPlan.priceAdjustment} />
            ) : null}
          </S.PaymentTermModalItemText>
          <PaymentPlanDetailsSummary
            autopayRequired={paymentPlan.autopayRequired}
            numberOfPayments={paymentPlan.installments.length}
          />
        </S.PaymentTermModalItemHeader>
        <S.PaymentTermModalItemDetailsButton>
          <Button
            hasPadding={false}
            onClick={() => setIsShowingDetails(!isShowingDetails)}
            rightIcon={detailsIcon}
            size="small"
            variant="text"
          >
            {detailsText}
          </Button>
        </S.PaymentTermModalItemDetailsButton>
      </S.PaymentTermModalItemRadioButton>
      {isShowingDetails ? (
        <PaymentPlanDetailsSchedule
          dueCheckout={paymentPlan.dueCheckout}
          isSelected={checked}
          itemId={itemId}
          installments={paymentPlan.installments}
          payLater={payLater}
        />
      ) : null}
    </>
  );
}

function PaymentPlanDetailsSummary({
  autopayRequired = false,
  numberOfPayments,
}: {
  autopayRequired?: boolean;
  numberOfPayments: number;
}) {
  const paymentsText =
    numberOfPayments === 1 ? `1 Payment` : `${numberOfPayments} Payments`;

  return (
    <Typography size="xs" variant="ui">
      <S.PaymentPlanDetailsSummary>
        {paymentsText}
        {autopayRequired ? ' / Autopay' : null}
      </S.PaymentPlanDetailsSummary>
    </Typography>
  );
}

type PaymentPlanDetailsScheduleProps = Pick<
  PaymentPlanSummary,
  'dueCheckout' | 'installments'
> & {
  isSelected?: boolean;
  itemId: string;
  payLater?: boolean;
};

function PaymentPlanDetailsSchedule({
  dueCheckout,
  isSelected,
  itemId,
  installments,
  payLater,
}: PaymentPlanDetailsScheduleProps) {
  const [currentPayLaterStatus, setCurrentPayLaterStatus] =
    useState<boolean>(!!payLater);

  useEffect(() => {
    if (!isSelected) {
      setCurrentPayLaterStatus(false);
    }
  }, [isSelected]);

  return (
    <S.PaymentPlanDetailsSchedule>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>#</TableHeader>
            <TableHeader>Amount due</TableHeader>
            <TableHeader>Date due</TableHeader>
            <TableHeader>Pay later</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {installments.map((item, index) => {
            const dueDate = DateTime.fromISO(item.installmentDate);
            const dueDateIsToday = DateTime.now()
              .startOf('day')
              .equals(dueDate.startOf('day'));

            return (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <PaymentPlanDetailsAmount
                    installmentTotal={item.installmentTotal}
                    percentage={item.percentage}
                  />
                </TableCell>
                <TableCell>
                  {dueDateIsToday || (index === 0 && !currentPayLaterStatus)
                    ? 'Today'
                    : dueDate.toFormat('MMM dd, yyyy')}
                </TableCell>
                <TableCell>
                  {index === 0 && !dueDateIsToday && !dueCheckout ? (
                    <S.PayLaterCheckbox>
                      <Checkbox
                        ariaLabel="Pay first payment later"
                        checked={currentPayLaterStatus}
                        disabled={!isSelected}
                        name={getItemPayLaterId(itemId)}
                        onCheckedChange={setCurrentPayLaterStatus}
                        id="pay-later"
                        size="large"
                        value={currentPayLaterStatus.toString()}
                      />
                    </S.PayLaterCheckbox>
                  ) : null}
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </S.PaymentPlanDetailsSchedule>
  );
}

function PaymentPlanDetailsAmount({
  installmentTotal,
  percentage,
}: Pick<PaymentPlanSummaryInstallment, 'installmentTotal' | 'percentage'>) {
  const formattedInstallmentTotal = formatAsCurrency(Number(installmentTotal));
  const amount = percentage
    ? `${percentage}% (${formattedInstallmentTotal})`
    : formattedInstallmentTotal;

  return <>{amount}</>;
}

function getItemPayLaterId(itemId: string): string {
  return `${itemId}-pay-later`;
}
